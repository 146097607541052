import * as React from 'react';
import { Box, Stack, Typography, Grid } from '@mui/material';
import { getImgPath } from '../../tools/StringTool';
import { paymentActions, paymentSlice, paymentObj } from '../../store/reducers/PaymentSlice';
import { useSelector, useDispatch } from 'react-redux';


export default function PayWayItem_OL(props) {
    const paymentData = useSelector(state => state.paymentData);
    const settingData = useSelector(state => state.settingData);

    function payWayTap() {
        props.onClick(props.type);
    }

    function getImg(type) {
        if ("RZ" === type) {
            return getImgPath('rg.png');
        } else if ("PM" === type) {
            return getImgPath('payermax.png');
        } else if ("XS" === type) {
            return getImgPath('xo.png');
        } else {
            return "";
        }
    }

    function getName(type) {
        if ("RZ" === type) {
            return "Razer Gold";
        } else if ("PM" === type) {
            return "Payermax";
        } else if ("XS" === type) {
            return "Xsolla";
        } else {
            return "";
        }
    }

    return (
        <Stack>
            <Stack
                onClick={payWayTap}
                component={'div'}
                sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'row',
                    height: settingData.isMobile ? '85rem' : '55rem',
                    bgcolor: '#2E1F1E66',
                    backdropFilter: 'blur(5px)',
                    WebkitBackdropFilter: 'blur(5px)',
                    border: 2,
                    borderColor: props.value == props.type ? '#B58E56': 'transparent',
                    color: 'white',
                    mb: 1,
                }}>

                <Box component={'img'}
                    src={getImg(props.type)}
                    sx={{
                        borderRadius: '3rem',
                        height: 0.7,
                        alignSelf: 'center',
                        ml: settingData.isMobile ? 4 : 5.5,
                        zIndex: '1',
                    }}>
                </Box>

                <Typography alignSelf={'center'}
                    sx={{
                        color: 'white',
                        fontSize: '22rem',
                        ml: 2,
                        fontWeight: 'bold',
                        fontFamily: window.MyFontA,
                    }}>
                    {getName(props.type)}
                </Typography>

                <Box component={'img'}
                    src={getImgPath('overlord/selected.png')}
                    sx={{
                        display: (props.value == props.type ? 'flex' : 'none'),
                        position: 'absolute',
                        height: '40rem',
                        ml: 0,
                    }}>
                </Box>
            </Stack>
        </Stack>
    )
}
