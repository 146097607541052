import * as APJRequest from '../tools/APJRequest';
import * as APJUrl from '../tools/APJUrl';
import * as StringTool from './StringTool';
import axios from 'axios';


export function checkRoleId(gameRoleId, onSuccess, onFail) {
    const param = {
        gameRoleId: gameRoleId
    };
    APJRequest.post(APJUrl.CheckRoleId, param, onSuccess, onFail);
}

export function requestProductList(onSuccess, onFail) {
    APJRequest.post(APJUrl.ProductList, null, (data) => {
        onSuccess(data);
    }, onFail);
}

export function requestPaymentUrl(type, roleId, productObj, quantity, callbackUrl, onSuccess, onFail) {
    const param = {
        gameRoleId: roleId,
        productId: productObj.productId,
        quantity: quantity,
        currency: productObj.currency,
        paymentSuccessReturnUrl: callbackUrl,
    };
    let url = '';
    if(type === 'RZ'){
        url = APJUrl.RazerPaymentUrl;
    }else if(type === 'PM'){
        url = APJUrl.PayermaxPaymentUrl;
    }else if(type === 'XS'){
        url = APJUrl.XsollaPaymentUrl;
    }

    APJRequest.post(url, param, (data)=>{
        onSuccess(data.paymentFrontendUrl);
    }, onFail);
}

export function checkPaymentResult(orderId, onSuccess, onFail) {
    if (StringTool.isEmpty(orderId)) {
        onFail(1300, 'Order id is empty!');
        return;
    }

    const param = {
        apjOrderId: orderId
    };
    APJRequest.post(APJUrl.CheckPaymentResult, param, onSuccess, onFail);
}


const seaCode = ["SG", "MY", "TH", "ID", "PH"]; //东南亚
export function getIPInfo(onSeaResult) {//是不是东南亚
    axios.post('https://eu-web-api.aplus-games.com/api/public/geoip', {
        headers: {},
    }).then(({ data }) => {
        if (data.ip) { //成功
            const ip = data.ip;
            const countryCode = data.country_code;
            if(isPass(seaCode, countryCode)){
                onSeaResult(true);
            }else{
                onSeaResult(false);
            }
        } else {
            onSeaResult(false);
        }
    }).catch(function (error) {
        console.log(error);
        onSeaResult(false);
    });
}

function isPass(ary, code) {
    var ret = false;
    for (var i = 0; i < ary.length; i++) {
      if (ary[i].toUpperCase() == code.toUpperCase()) {
        ret = true;
        break;
      }
    }
    return ret;
  }