import * as React from 'react';
import * as StringTool from '../tools/StringTool';
import { getImgPath } from '../tools/StringTool';
import { useParams, useLocation, useSearchParams } from 'react-router-dom'
import { Grid, Box, Stack, Typography } from '@mui/material';
import Payment_SFD from '../components/Payment_SFD';
import Payment_OL from '../components/Payment_OL';
import * as APJHttp from '../tools/APJHttp';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../store/reducers/SettingSlice';

import LoadingComponent from '../components/LoadingComponent';
import ToastComponent from '../components/ToastComponent';

export default function PaymentPage() {

  const dispatch = useDispatch();
  const { state, search } = useLocation();
  const [page, setPage] = React.useState(null);
  const [paymentType, setPaymentType] = React.useState(null);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const gameId = searchParams.get('gameId');
    setPaymentType(searchParams.get('paymentType'));
    refreshLocal(gameId);
  }, []);


  function refreshLocal(gameId) {
    let productMd = StringTool.searchGame(gameId);
    if (productMd == null) {
      setPage('error');
    } else {
      StringTool.saveSessionString(StringTool.GameId, gameId);
      StringTool.saveSessionString(StringTool.AppKey, productMd.appKey);
      StringTool.saveSessionString(StringTool.AppChannelKey, productMd.appChannelKey);
      setPage(gameId);
    }
  }

  return (
    <Stack>
      {page == 'error' && <Error />}
      {page == 'sfd' && <Payment_SFD paymentType={paymentType} />}
      {(page == 'overlord' || page == 'overlord_sea')  && <Payment_OL gameId={page} paymentType={paymentType} />}

      <LoadingComponent />
      <ToastComponent />
    </Stack>
  );
}


function Error() {
  return (
    <Stack sx={{
      height: '100vh',
      color: 'white',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Box component={'img'}
        src={getImgPath('error_icon.png')}
        sx={{
          width: '90px',
          mb: 2,
        }} />
      <Typography sx={{
        fontSize: 17
      }}>
        Missing params, please check !
      </Typography>
    </Stack>
  );
}